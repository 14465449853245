<template>
  <div>
    <Row v-if="gbId!==null" class="text-center" style="margin-top: -8px;">
      <i-col span="24">
        <RadioGroup v-model="queryType" type="button" button-style="solid">
            <Radio label="2">执行额</Radio>
            <Radio label="1">去化率</Radio>
            <Radio label="3">客流量</Radio>
        </RadioGroup>
      </i-col>
    </Row>

    <div style="width:100%;" :style="{height: gbId===null? '280px':'260px'}" id="stationChartReport"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getStorage } from '@/utils/storage'
import { computeAssetResourceUsedRateAndPassengerFlow, listStationUsedRateAndExecuteGbMonth } from '@/api/dw/station'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      dataSourcePie: null,
      queryType: '2' // 1:去化率;2:执行额;3:客流量
    }
  },
  methods: {
    initPageData () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        assetId: this.assetId,
        companyId: this.companyId
      }

      computeAssetResourceUsedRateAndPassengerFlow(queryModel).then(res => {
        const xLabels = []
        const yData1 = []
        const yData2 = []
        const yData3 = []
        res.forEach(element => {
          if (!xLabels.includes(element.x)) {
            xLabels.push(element.x)
          }

          if (element.s === '客流量') {
            yData1.push(element.y)
          } else if (element.s === '执行额') {
            yData2.push(element.y)
          } else if (element.s === '去化率') {
            yData3.push(element.y)
          }
        })

        this.initReportChart(xLabels, yData1, yData2, yData3)
      })
    },
    initReportChart (xLabels, yData1, yData2, yData3) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('stationChartReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('stationChartReport'))
          const colors = ['#5470C6', '#91CC75', '#EE6666']
          const option = {
            color: colors,
            title: {
              text: '站点客流量、合同执行额、去化率对比图',
              textStyle: {
                color: this.themeIsDark ? '#dcdee2' : '#515a6e'
              }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            grid: {
              left: 50,
              right: 130,
              top: 70,
              bottom: 30
            },
            legend: {
              data: ['客流量', '执行额', '去化率'],
              right: 50,
              textStyle: {
                color: this.themeIsDark ? '#fff' : '#000'
              }
            },
            xAxis: [
              {
                type: 'category',
                axisTick: {
                  alignWithLabel: true
                },
                axisLabel: {
                  color: '#c5c8ce'
                },
                data: xLabels
              }
            ],
            yAxis: [
              {
                type: 'value',
                name: '客流量(人次)',
                position: 'right',
                alignTicks: true,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colors[0]
                  }
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    opacity: 0.1
                  }
                }
              },
              {
                type: 'value',
                name: '执行额(元)',
                position: 'right',
                alignTicks: true,
                offset: 70,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colors[1]
                  }
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    opacity: 0.1
                  }
                }
              },
              {
                type: 'value',
                name: '去化率',
                position: 'left',
                alignTicks: true,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colors[2]
                  }
                },
                axisLabel: {
                  formatter: '{value} %'
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    opacity: 0.1
                  }
                }
              }
            ],
            series: [
              {
                name: '客流量',
                type: 'bar',
                data: yData1
              },
              {
                name: '执行额',
                type: 'bar',
                yAxisIndex: 1,
                data: yData2
              },
              {
                name: '去化率',
                type: 'line',
                smooth: true,
                yAxisIndex: 2,
                data: yData3
              }
            ]
          }

          this.dataSourcePie.setOption(option, true)
          const that = this
          window.addEventListener('resize', function () {
            that.dataSourcePie.resize()
          })
        }
      })
    },
    loadStationUseGb () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        assetId: this.assetId,
        companyId: this.companyId,

        queryType: this.queryType,
        stationId: this.gbId
      }

      listStationUsedRateAndExecuteGbMonth(queryModel).then(res => {
        const legends = []
        const xLabels = []
        const ySeries = []

        res.forEach(element => {
          if (!legends.includes(element.s)) {
            legends.push(element.s)
          }

          if (!xLabels.includes(element.x)) {
            xLabels.push(element.x)
          }
        })

        legends.forEach(element => {
          ySeries.push({
            name: element,
            type: 'line',
            smooth: true,
            label: {
              show: true,
              position: 'top',
              valueAnimation: true,
              color: '#fff',
              rich: {
                color: '#fff'
              }
            },
            animationDuration: 2000,
            data: res.filter(x => x.s === element).map(x => x.y)
          })
        })

        this.initReportChartByStationGb(legends, xLabels, ySeries)
      })
    },
    initReportChartByStationGb (legends, xLabels, ySeries) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('stationChartReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('stationChartReport'))
          const option = {
            title: {
              text: '站点趋势观察',
              textStyle: {
                color: '#dcdee2'
              }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              data: legends,
              right: 50,
              textStyle: {
                color: '#fff'
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: 10,
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: xLabels,
              axisLabel: {
                color: '#c5c8ce'
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: false,
                lineStyle: {
                  opacity: 0.1
                }
              },
              axisLine: {
                show: true
              }
            },
            series: ySeries
          }

          this.dataSourcePie.setOption(option, true)
        }
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    assetId () {
      return this.$store.state.situationWeb.assetId
    },
    beignUpdateBottomAndRight () {
      return this.$store.state.situationWeb.beignUpdateBottomAndRight
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    themeIsDark () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark'
    }
  },
  watch: {
    beignUpdateBottomAndRight () {
      if (this.gbId) {
        this.queryType = '2'
        this.loadStationUseGb()
      } else {
        this.initPageData()
      }
    },
    queryType () {
      this.loadStationUseGb()
    }
  }
}
</script>
